import { useEffect, useState } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import logoLivingImg from "../../assets/logos/logo-living.svg";
import blueQuadraLogo from "../../assets/logos/logo-quadra-blue.svg";
import quadraLogo from "../../assets/logos/logo-quadra.svg";
import { useFetchByContrato } from "../../services/useFetchByContrato";
import { FormMainVenture } from "../Form/Form";
import { ResponsiveNavMenu } from "../ResponsiveMenu";
import { Hero, Menu } from "./styles";

export type navProps = {
  navLinks: navLink[];
};

export type navLink = {
  link: string;
  slug: string;
};

export function Header() {
  const [isScrollOnTop, setIsScrollOnTop] = useState<boolean>(true);

  const { data } = useFetchByContrato<navProps>("/nav");

  useEffect(() => {
    window.onscroll = () =>
      window.pageYOffset <= 50
        ? setIsScrollOnTop(true)
        : setIsScrollOnTop(false);
  });

  return (
    <>
      <Menu isScrollOnTop={isScrollOnTop}>
        <div className="grid-container">
          <div className="inside-grid-container">
            <div className="flex-container">
              <div className="menu-background" />
              <div className="logo-container">
                {isScrollOnTop ? (
                  <img src={quadraLogo} className="logo-quadra" />
                ) : (
                  <img src={blueQuadraLogo} className="logo-quadra-blue" />
                )}
              </div>
              <div className="nav-buttons-container">
                <div className="nav-links-container">
                  {data?.navLinks?.map((navLink: navLink) => (
                    <a
                      href={navLink.slug}
                      className="nav-link"
                      key={navLink.link}
                    >
                      {navLink.link}
                    </a>
                  ))}
                </div>
              </div>
              <ResponsiveNavMenu isScrollOnTop={isScrollOnTop} />
            </div>
          </div>
        </div>
      </Menu>
      <Hero>
        <div className="container-logo">
          <img src={logoLivingImg} alt="Living" />
          {/*   <p className="description">
            A essência da sofisticação é viver numa das regiões mais valorizadas
            de Belém, no bairro de Batista Campos
          </p> */}
          <div className="container-info">
            <button
              onClick={(e) => {
                e.preventDefault(); // Impede o comportamento padrão de navegação
                const formElement =
                  document.getElementById("form-main-venture");
                if (formElement) {
                  formElement.scrollIntoView({ behavior: "smooth" }); // Faz o scroll suave
                }
              }}
              type="submit"
              value=""
            >
              QUERO MAIS INFORMAÇÕES
            </button>
          </div>
          <div>
            <ScrollAnimation animateIn="animate__fadeIn">
              <FormMainVenture />
            </ScrollAnimation>
          </div>
        </div>
      </Hero>
    </>
  );
}
