import styled from "styled-components";

import backgroundHeroImg from "../../assets/body-bg.webp";

type MenuProps = {
  isScrollOnTop: boolean;
};

export const Menu = styled.nav<MenuProps>`
  position: fixed;
  height: ${({ isScrollOnTop }) => (isScrollOnTop ? "6.5rem" : "5rem")};
  width: 100%;
  left: 0;
  z-index: 999;
  background-color: transparent;
  transition: 0.2s ease-in-out;
  padding: ${({ isScrollOnTop }) => (isScrollOnTop ? "0" : "4px")};

  .grid-container {
    display: grid;
    grid-template-columns: repeat(12, minmax(0, 1fr));
    height: ${({ isScrollOnTop }) => (isScrollOnTop ? "6.5rem" : "5rem")};
    align-items: center;
  }

  .inside-grid-container {
    grid-column: 2 / 12;
  }

  .flex-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .menu-background {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: ${({ isScrollOnTop }) => (isScrollOnTop ? "7.5rem" : "5.4rem")};
    width: 100vw;
    background-color: ${({ isScrollOnTop }) =>
      isScrollOnTop ? "transparent" : "#fff"};
    transition: 0.2s ease-in-out;
  }

  .logo-container {
    grid-column: 2 / 3;
  }

  .logo-quadra {
    height: 100px;
    transform: ${({ isScrollOnTop }) =>
      isScrollOnTop
        ? "translate(-0.5px ,calc(30px + 0.5rem))"
        : "translateX(0)"};
    object-fit: contain;
    z-index: 10;
    transition: 0.2s ease-in-out;
  }

  .logo-quadra-blue {
    height: 49.01px;
    width: 59.4px;
    transform: ${({ isScrollOnTop }) =>
      isScrollOnTop ? "translateY(calc(30px + 0.5rem))" : "translateY(0)"};
    transform: ${({ isScrollOnTop }) =>
      isScrollOnTop ? "translateX(0)" : "translateX(44px)"};
    object-fit: contain;
    z-index: 10;
    transition: 0.2s ease-in-out;
  }

  .nav-buttons-container {
    display: flex;
    align-items: center;
    grid-column-end: 12;

    transform: ${({ isScrollOnTop }) =>
      isScrollOnTop ? "translate(-4px, 8px)" : "translateX(0)"};

    .search-icon-container {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 42px;
      height: 42px;
      background-color: #525252;
      border-radius: 50%;
      opacity: 0.9;

      img {
        height: 20px;
        width: 20px;

        filter: drop-shadow(0 10px 8px rgb(0 0 0 / 0.04))
          drop-shadow(0 4px 3px rgb(0 0 0 / 0.1));
      }
    }

    @media only screen and (max-width: 1215px) {
      display: none;
    }

    /* 
    Small ≥576px	
    Medium ≥768px	
    Large ≥992px	
    Extra large ≥1200px 
  */
  }

  .nav-links-container {
    display: flex;
  }

  .nav-link {
    position: relative;
    font-size: 1.125rem;
    padding: 0 0.75rem;
    transition: color 0.15s ease-in-out, opacity 0.15s ease-in-out,
      border-color 0.15s ease-in-out;
    text-shadow: ${({ isScrollOnTop }) =>
      isScrollOnTop
        ? "0px 4px 3px rgba(0, 0, 0, 0.4), 0px 8px 13px rgba(0, 0, 0, 0.1), 0px 18px 23px rgba(0, 0, 0, 0.1)"
        : "none"};
    color: ${({ isScrollOnTop }) =>
      isScrollOnTop ? "var(--light-gray-color)" : "#1891f1"};

    &.active::before {
      content: "";
      position: absolute;
      left: ${({ isScrollOnTop }) => (isScrollOnTop ? "16.5px" : "20px")};
      bottom: -4px;
      width: ${({ isScrollOnTop }) =>
        isScrollOnTop ? "calc(100% - 32px)" : "calc(100% - 40px)"};
      height: 2px;
      background-color: ${({ isScrollOnTop }) =>
        isScrollOnTop ? "#fff" : "#1891f1"};
      border-radius: 4px;
      transition: all 0.3s ease-in-out;
    }

    &:hover {
      opacity: 0.85;
    }
  }
`;

export const Hero = styled.div`
  height: 100vh;
  background-image: url(${backgroundHeroImg});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100%;

  .container-logo {
    width: 100%;
    justify-content: space-between;
    align-items: center;
    display: flex;
    height: 100vh;
    align-items: center;
    padding: 3rem 10rem;
    background-repeat: no-repeat;
    background-position: center;
    .description {
      display: none !important;
    }
    @media (max-width: 991.98px) {
      .description {
        display: block !important;
        color: white;
        font-size: 1.3rem;
        font-weight: 500;
        margin: 10px 0px 0px 10px;
      }
    }
    .container-info {
      display: none;
      width: 100%;
      button {
        background: linear-gradient(90deg, #00a7ff 0%, #0071ff 100%);
        width: 80%;
        padding: 0.9rem;
        border-radius: 30px;
        border: none;
        color: var(--shape-color);
        transition: all 0.2s ease;
        margin-top: 2rem;
        font-family: "Gilroy ExtraBold", sans-serif;
        font-weight: bold;
        letter-spacing: 0.1em !important;
        &:hover {
          filter: brightness(0.9);
        }
      }
    }
    @media (max-width: 991.98px) {
      flex-direction: column;
      justify-content: center;
      align-items: start;
      padding: 3rem 2rem;
      .container-info {
        display: flex;
      }
      img {
        width: 220px;
      }

    }
      @media (max-width: 991.98px) {
      img {
        width: 220px;
      }
    }
    div {
      .section-formulario-form {
        width: 100%;
        margin-top: 5rem;
        max-width: 20rem;
        padding: 0.7rem 0px 0px 0px;
        background: transparent linear-gradient(90deg, #00a7ff 0%, #0071ff 100%)
          no-repeat padding-box;
        box-shadow: 2px 2px 12px 2px rgba(0, 0, 0, 0.1);
        .text-red {
          color: red;
          font-size: 0.7rem;
        }
        .header {
          div {
            h3 {
              font-size: 1rem;
              font-weight: 700;
              font-family: 'Gilroy ExtraBold', sans-serif;
              font-weight: bold;
              letter-spacing: 0.1em !important;
            }
            p {
              font-size: 0.9rem;
            }
          }
          height: 6rem;
          display: grid;
          padding: 0 1rem;
          place-items: center;
          img {
            width: 200px;
          }
          h3,
          p {
            text-align: center;
            color: #fff;
            font-family: 'Gilroy Light';
          }
        }
        .content-inputs {
          display: flex;
          flex-direction: column;
          background-color: var(--shape-color);
          padding: 0rem 1.2rem 1.2rem 1.2rem;
          #terms {
            margin-top: 1rem;
            span {
              font-size: 0.8rem;
              display: inline;
              align-items: start;
              input[type='checkbox'] {
                margin-right: 0.5rem;
                width: 0.8rem;
                height: 0.8rem;
              }
              p {
                font-size: 0.8rem;
                display: inline-block;
                width: 100%;
              }
              .blue {
                margin-left: 0.2rem;
                color: #1891f1;
              }
            }
          }
          button {
            background: linear-gradient(90deg, #00a7ff 0%, #0071ff 100%);
            width: 100%;
            padding: 0.9rem;
            border-radius: 30px;
            border: none;
            color: var(--shape-color);
            transition: all 0.2s ease;
            margin-top: 0.6rem;
            font-size: 0.9rem;
            font-family: 'Gilroy ExtraBold', sans-serif;
            font-weight: bold;
            letter-spacing: 0.1em !important;
            &:hover {
              filter: brightness(0.9);
            }
          }
          @media (max-width: 767.98px) {
            padding: 1rem;
          }
          input,
          label {
            height: 1.9rem;
            font-size: 0.8rem;
            line-height: 3rem;
            width: 100%;
          }
          label {
            color: #00a7ff;
          }
          input,
          .input-theme {
            border: none;
            border-bottom: 2px solid rgba(0, 0, 0, 0.5);
            padding-left: 1rem;
            &:focus {
              outline: none;
              border: none;
              border-bottom: 2px solid #00a7ff;
            }
            &[type='submit'] {
              background: linear-gradient(90deg, #00a7ff 0%, #0071ff 100%);
              border-radius: 20%;
              color: var(--shape-color);
              transition: all 0.2s ease;
              margin-top: 5rem;
              &:hover {
                filter: brightness(0.9);
              }
            }
          }
          &::placeholder {
            color: #c2c2c2;
          }
        }
        @media (max-width: 991.98px) {
          display: none;
        }
      }
    }
    @media only screen and (max-width: 875px) {
      margin-right: 0;
      img {
        max-width: 100%;
        height: auto;
      }
  }
`;
